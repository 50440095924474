import Vue from 'vue';
import Router from 'vue-router';

import Landing from './agelessPages/Landing.vue';
import Services from './agelessPages/Services.vue';
import PriceList from './agelessPages/PriceList.vue';
import Contact from './agelessPages/Contact.vue';
import MainNavbar from './agelessLayout/MainNavbar.vue';
import MainFooter from './agelessLayout/MainFooter.vue';

Vue.use(Router);

export default new Router({
  mode: 'history',
  linkExactActiveClass: 'active',
  routes: [
    
    {
      path: '/',
      name: 'landing',
      components: { default: Landing, header: MainNavbar, footer: MainFooter },
      props: {
        header: { colorOnScroll: 300 },
        footer: { backgroundColor: 'black' }
      }
    },
    {
      path: '/services',
      name: 'Services',
      components: { default: Services, header: MainNavbar },
      props: {
        header: { colorOnScroll: 300 }
      }
    },
    {
      path: '/pricelist',
      name: 'Price List',
      components: { default: PriceList, header: MainNavbar, footer: MainFooter },
      props: {
        header: { colorOnScroll: 300 },
        footer: { backgroundColor: 'black' }
      }
    },
    {
      path: '/contact',
      name: 'Contact',
      components: { default: Contact, header: MainNavbar, footer: MainFooter },
      props: {
        header: { colorOnScroll: 4 },
        footer: { backgroundColor: 'black' }
      }
    }
  ],
  scrollBehavior: to => {
    if (to.hash) {
      return { selector: to.hash };
    } else {
      return { x: 0, y: 0 };
    }
  }
});
